import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import FeatureBlock from './feature-block'

export default function FeatureGrid() {
  const columnPattern = [5, 7, 7, 5]
  const { features } = useStaticQuery(
    graphql`
      query {
        features: allFeaturesYaml(limit: 9999) {
          nodes {
            image
            title
            link
            tagline
          }
        }
      }
    `
  )

  return (
    <section id="feature-grid">
      <Row className="g-0">
        {features.nodes.map((feature, index) => (
          <Col key={index} lg={columnPattern[index % columnPattern.length]}>
            <FeatureBlock
              title={feature.title}
              imageKey={feature.image}
              link={feature.link}
              overlayColor={`bg-accent${index + 1}`}
              tagline={feature.tagline}
            />
          </Col>
        ))}
      </Row>
    </section>
  )
}
