import React, { useEffect, useState } from 'react'
import { motion } from 'framer-motion'
import NavLink from './nav-link'

import { getSrcSet } from 'utils/get-src-set'
import { useElementSize } from 'utils/use-element-size'
import { useMediaImages } from 'utils/use-media-images'

import './feature-block.scss'

const renderOverlay = (color) => {
  return (
    <motion.div
      className={`feature-block-overlay ${color}`}
      initial={{
        opacity: 0.85,
      }}
      whileHover={{
        opacity: 0.25,
        transition: { duration: 0.5 },
      }}
      whileTap={{
        opacity: 0.25,
        transition: { duration: 0.25 },
      }}
    />
  )
}

export default function FeatureBlock({ title, imageKey, link, overlayColor, tagline }) {
  const [block, setBlock] = useState(null)
  const blockSize = useElementSize(block)
  const images = useMediaImages()
  const [image, setImage] = useState(null)
  const [imageHeight, setImageHeight] = useState(0)
  const [imageWidth, setImageWidth] = useState(0)

  useEffect(() => {
    setImage(images?.find((n) => n.key === imageKey))
  }, [])

  useEffect(() => {
    if (image?.width / image?.height > blockSize?.width / blockSize?.height) {
      setImageHeight(blockSize?.height)
      setImageWidth(Math.round(blockSize?.height * (image?.width / image?.height)))
    } else {
      setImageWidth(blockSize?.width)
      setImageHeight(Math.round(blockSize?.width * (image?.height / image?.width)))
    }
  }, [blockSize])

  return (
    <div className="feature-block" ref={setBlock}>
      <article>
        <div className="feature-block-image">
          {image && (
            <img
              src={`${image?.cdn}/cover960x540`}
              srcSet={getSrcSet(image?.cdn, '16x9')}
              sizes={'(min-width: 992px) 60vw, 100vw'}
              style={{ background: `url(${image?.hash}) center center / cover` }}
              width={imageWidth || 0}
              height={imageHeight || 0}
              alt={title}
            />
          )}
        </div>
        <div className="feature-block-content">
          <h3>{title}</h3>
          <p>{tagline}</p>
        </div>
      </article>
      <NavLink href={link} className="feature-block-link">
        {renderOverlay(overlayColor)}
        <div className="d-none">{title}</div>
      </NavLink>
    </div>
  )
}
