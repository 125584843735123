import React, { useEffect, useState } from 'react'
import { useWindowHeight, useWindowWidth } from '@react-hook/window-size'

import Button from 'react-bootstrap/Button'
import { CaretDownFill } from 'react-bootstrap-icons'

import { getSrcSet } from 'utils/get-src-set'
import { useMediaImages } from 'utils/use-media-images'

import './feature-banner.scss'

const renderBackground = (imageKey) => {
  const images = useMediaImages()
  const [image, setImage] = useState(null)
  const [imageHeight, setImageHeight] = useState(0)
  const [imageWidth, setImageWidth] = useState(0)
  const windowHeight = useWindowHeight()
  const windowWidth = useWindowWidth()

  useEffect(() => {
    setImage(images?.find((n) => n.key === imageKey))
  }, [])

  useEffect(() => {
    if (image?.width / image?.height > windowWidth / windowHeight) {
      setImageHeight(windowHeight)
      setImageHeight(Math.round(windowHeight * (image?.width / image?.height)))
    } else {
      setImageWidth(windowWidth)
      setImageHeight(Math.round(windowWidth * (image?.height / image?.width)))
    }
  }, [windowWidth, windowWidth])

  return (
    <div className="feature-banner-image">
      {image?.cdn && (
        <img
          src={`${image?.cdn}/cover960x540`}
          srcSet={getSrcSet(image?.cdn, '16x9')}
          sizes={'100vw'}
          style={{ background: `url(${image?.hash}) center center / cover` }}
          width={imageWidth || 0}
          height={imageHeight || 0}
          alt="Featured projects"
        />
      )}
    </div>
  )
}

export default function FeatureBanner({ imageKey }) {
  return (
    <div className="feature-banner">
      <section>
        {renderBackground(imageKey)}
        <div className="feature-banner-content">
          <h2>Portfolio</h2>
          <p>
            Selected intermedia art projects
            <span className="d-none d-sm-inline">{' from '}</span>
            <span className="d-none d-sm-block">John Crawford and colleagues</span>
          </p>
          <Button href="#feature-grid" variant="banner">
            More <CaretDownFill className="ms-1" />
          </Button>
        </div>
      </section>
    </div>
  )
}
