import { graphql, useStaticQuery } from 'gatsby'

export const useMediaImages = () => {
  const { allMediaImage } = useStaticQuery(
    graphql`
      query MediaImageQuery {
        allMediaImage {
          nodes {
            cdn
            hash
            height
            key
            publish
            width
          }
        }
      }
    `
  )
  return allMediaImage.nodes
}