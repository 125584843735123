import React from 'react'

import FeatureBanner from 'components/feature-banner'
import FeatureGrid from 'components/feature-grid'
import Meta from 'components/meta'
import Wrapper from 'components/wrapper'

export default function IndexPage({ location }) {
  return (
    <Wrapper
      location={location}
      variant="features"
      bodyClass="bg-body-darker"
      wrapperClass="d-flex w-100 h-100"
    >
      <Meta />
      <FeatureBanner imageKey="yomo-2019:yomo-uci-emergence-0106_16x9" />
      <FeatureGrid />
    </Wrapper>
  )
}
