export const getSrcSet = (imageKey, aspectRatio) => {
  let variants = []
  if (aspectRatio === '16x9') {
    variants = [
      [320, 180],
      [480, 270],
      [640, 360],
      [960, 540],
      [1280, 720],
      [1920, 1080],
      [2560, 1440],
    ]
  } else if (aspectRatio === '4x3') {
    variants = []
  } else if (aspectRatio === '1x1') {
    variants = []
  }
  return variants.map((v) => `${imageKey}/cover${v[0]}x${v[1]} ${v[0]}w`).join(', ')
}
